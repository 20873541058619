<template>
  <div class="hexiao">
    <div class="hexiao-search">
      <div class="hexiao-search-box">
        <div class="hexiao-search-box-l">
          <img src="../../assets/hexiao.png" alt="请输入核销码" />
        </div>
        <div class="hexiao-search-box-r">
          <div class="hexiao-search-box-r-box">
            <p class="hexiao-search-box-r-text">请输入核销编码进行查询</p>
            <el-input
              placeholder="请输入核销码"
              v-model="datas.code"
              class="input-with-select"
            >
              <el-button
                class="el-button el-button--primary"
                slot="append"
                @click="getInfo"
                >核销查询</el-button
              >
            </el-input>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="核销详情"
      :visible.sync="dialogVisible"
      :before-close="closes"
      width="60%"
    >
      <div class="alerts">
        <div class="alerts-list">
          <span>核销码：</span>
          <div class="alerts-list-item">
            {{ form.code }}
          </div>
        </div>
        <div class="alerts-list">
          <span>订单金额：</span>
          <div class="alerts-list-item">￥{{ form.amount }}</div>
        </div>
        <div class="alerts-list">
          <span>订单数量：</span>
          <div class="alerts-list-item">
            {{ form.num }}
          </div>
        </div>
        <div class="alerts-list">
          <span>创建时间：</span>
          <div class="alerts-list-item">
            {{ form.created_at }}
          </div>
        </div>
        <div class="alerts-list">
          <span>订单状态：</span>
          <div class="alerts-list-item">
            {{ form.status > 1 ? "已核销" : "未核销" }}
          </div>
        </div>
        <div class="alerts-list">
          <span>订单商品：</span>
          <div class="alerts-list-item">
            <el-table :data="[form.goods_info]" :stripe="$table('stripe')" :border="$table('border')" :header-cell-style="{ background: $table('bgcolor') }" style="width: 100%">
              <el-table-column prop="goods_id" label="id"> </el-table-column>
              <el-table-column prop="name" label="名称"> </el-table-column>
              <el-table-column prop="picture" label="图片">
                <template slot-scope="scope">
                 <div class="imgs">
                    <div class="demo-image__preview">
                    <el-image
                      :src="scope.row.picture"
                      :preview-src-list="[scope.row.picture]"
                    >
                    </el-image>
                  </div>
                 </div>
                </template>
              </el-table-column>
              <el-table-column prop="goods_type" label="类型">
                <template slot-scope="scope">
                  {{ scope.row.goods_type > 1 ? "项目" : "商品" }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="alerts-list" v-if="form.status < 2">
          <span>选择员工：</span>
          <div class="alerts-list-item">
            <el-select
              v-model="datas.staff_ids"
              multiple
              filterable
              placeholder="请选择员工"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="alerts-list">
          <span>订单备注：</span>
          <div class="alerts-list-item">
            <el-input
              type="textarea"
              placeholder="请输入订单备注..."
              v-model="form.remark"
              maxlength="30"
              show-word-limit
            >
            </el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closes">取 消</el-button>
        <el-button type="primary" v-if="form.status < 2" @click="setList"
          >核 销</el-button
        >
        <el-button type="primary" v-if="form.status > 1" @click="closes"
          >已 核 销</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datas: {
        code: "",
        staff_ids: "",
        remark: "",
      },
      dialogVisible: false,
      form: {},
      options: [],
    };
  },
  methods: {
    getStaff() {
      this.$axios({
        href: "/api/app/meiye/user/store",
        data: { status: 1 },
      }).then((res) => {
        console.log(res);
        this.options = res.data;
      });
    },
    setList() {
      let d = JSON.parse(JSON.stringify(this.datas));
      if (!d.code) {
        this.$message.error("核销码不能为空");
        return false;
      }
      if (!d.staff_ids[0]) {
        this.$message.error("请选择员工");
        return false;
      }

      d.staff_ids = JSON.stringify(d.staff_ids);

      this.$confirm("此操作将核销该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            href: "/api/app/meiye/payment/qrcode",
            data: d,
          }).then(() => {
            this.closes();
            this.goOrder();
            this.$message({
              type: "success",
              message: "核销成功!",
            });
          });
        })
        .catch(() => {});
    },
    getInfo() {
      if (!/^[A-Za-z0-9]+$/.test(this.datas.code)) {
        this.$message.error("请输入正确的核销码，核销码格式为数字加字母组成");
        return false;
      }

      this.$axios({
        href: "/api/app/meiye/member/wechat_order_code",
        data: this.datas,
      }).then((res) => {
        console.log(res);
        this.form = res.data.data;
        this.dialogVisible = !this.dialogVisible;
      });
    },
    closes() {
      this.dialogVisible = !this.dialogVisible;
      // this.form = {};
      this.datas = {
        code: this.datas.code,
        staff_ids: "",
        remark: "",
      };
    },
    goOrder() {
      this.$confirm("核销完成，是否跳转到订单页面?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push({
            path: "/order",
          });
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.getStaff();
  },
};
</script>

<style lang="scss" scoped>
.hexiao {
  width: 100%;
  height: 100%;
  background-image: url("./../../assets/bg.png");
  background-size: cover;
  // background: #be93c5; /* fallback for old browsers */
  // background: -webkit-linear-gradient(
  //   to right,
  //   #7bc6cc,
  //   #be93c5
  // ); /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(
  //   to right,
  //   #7bc6cc,
  //   #be93c5
  // ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  &-search {
    width: 100%;
    height: 100%;
    padding: 10% 6% 10% 6%;
    box-sizing: border-box;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    &-box {
      width: 100%;
      height: 571px;
      background: #ffffff;
      border: 1px solid #f2f2f2;
      border-radius: 0px;
      font-size: 14px;
      font-weight: Normal;
      text-align: center;
      color: #777777;
      display: flex;
      align-items: center;
      justify-content: center;
      &-l {
        width: 40%;
        height: 100%;
        // background: red;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 80%;
          height: auto;
        }
      }
      &-r {
        width: 60%;
        height: 100%;
        // padding: 0 10%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        &-box {
          width: 100%;
          padding: 0 5%;
        box-sizing: border-box;
          height: auto;
        }
        &-text {
          // width: 198px;
          // height: 24px;
          font-size: 18px;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: Bold;
          text-align: left;
          color: #6f7799;
          line-height: 6px;
        }
      }
    }
  }
}
.alerts {
  width: 100%;
  height: 100%;
  &-list,
  &-lists {
    width: 100%;
    min-height: 60px;
    margin: 10px auto;
    // background: red;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span:nth-of-type(1) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 120px;
    }
    &-item {
      width: 90%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
.imgs{
  width: 50px;
  height: 50px;
}
</style>
<style moudle>
.hexiao .el-input-group__append,
.hexiao .el-input-group__prepend {
  background: #8088a8;
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Normal;
  font-weight: Normal;
  text-align: center;
  color: #ffff;
}
</style>
